import React from "react";
import "./SearchBox.scss";

let inputRef = React.createRef();

const SearchBox = ({
  handleSearch,
  handleSearchSubmit,
  searchPlaceHolder,
  withBackground,
  width = 300,
  Ecommerce,
  ...props
}) => {
  return (
    <form
      onSubmit={(...props) => {
        handleSearchSubmit(...props);
        inputRef.current.blur();
      }}
      style={{ position: "relative" }}
    >
      <input
        ref={inputRef}
        style={{ width }}
        styleName={`search__input ${
          withBackground ? "greyOutline" : "whiteOutline"
        }`}
        className="font-default"
        type="text"
        placeholder={searchPlaceHolder ? searchPlaceHolder : ""}
        onChange={handleSearch}
        {...props}
        data-cy="search-input"
        minLength={Ecommerce ? 2 : ""}
      />
      <i
        data-cy="search-icon"
        className="fal fa-search"
        onClick={handleSearchSubmit}
        styleName={`${withBackground ? "grey" : "white"} searchIcon`}
      />
    </form>
  );
};

export default SearchBox;
