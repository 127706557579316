import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import { exitButton } from "_shared/modal";
import BuildingInfo from "../../BuildingInfo/BuildingInfo";
import { Scrollbars } from "react-custom-scrollbars";
import styles from "./BuildingModal.scss";

const BuildingModal = ({ 
  buildingData,
  isMedScreen, 
   height, 
   width }) => {
  const [openModal, setOpenModal] = useState(false);

  const openModalClick = () => {
    setOpenModal(!openModal);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div
      className={`flex w-auto cursor-pointer justify-between border border-solid border-[#fafbfb] bg-white md:w-[200px] lg:w-[300px] rounded-[4px] ${isMedScreen ? "h-fit" : "h-[34px]"}`}
      // className="mx-2 flex h-auto w-auto cursor-pointer flex-row rounded border-0 bg-teal-tints-70 py-2 px-4 font-bold text-white" //button
      // style={{
      //   width: width,
      //   height: "100dvh",
      // }}
      onClick={openModalClick}
    >
      <div //take out for button
        data-testid="mobile-buildinginfo-container"
        className={`ml-4 ${isMedScreen ? "h-11" : ""} flex items-center text-sm font-normal uppercase text-[#9da3a9]`}
      >
        Building Info
      </div>
      <i //take out for button
        className="fal fa-chevron-down fa-lg ml-2 mr-4 flex cursor-pointer items-center text-[#e3e5e6]"
        onClick={openModalClick}
      />
      <Modal
        classNames={{
          modal: styles.modalStyles,
          overlay: styles.noOverlay,
          closeButton: styles.exitButton,
        }}
        open={openModal}
        onClose={closeModal}
        closeIconSvgPath={exitButton}
      >
        <Scrollbars
          autoHide
          autoHideDuration={200}
          universal
          className="flex h-[100dvh] flex-col overflow-x-hidden"
        >
          <BuildingInfo data={buildingData} height={height} width={width} />
        </Scrollbars>
      </Modal>
    </div>
  );
};

export default BuildingModal;
