import React from "react";
import Star from "../Star/Star";
import propTypes from "prop-types";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { ToTitleCase } from "_shared/helpers";
import "./SubNav.scss";

const SubNav = ({
  resetToTop,
  height,
  width,
  page,
  currentTab,
  setCurrentTab,
  TeamId,
  isMobile,
  isMobileCitySelector = false,
  history,
  closeModal,
  resources,
}) => {
  const { url: currentPath } = useRouteMatch();

  const handleTabClick = (url) => {
    if (closeModal) {
      closeModal();
    }
    history.push(url);
  };

  const getNavLinkClass = (url) => {
    if (resources) {
      return currentPath.indexOf(url) !== -1 ? "newactive" : "";
    }

    return currentPath === url ? "newactive" : "";
  };

  const getTitleName = (Tab) => {
    const pageLocation = currentPath.split("/");
    if (pageLocation[1] === "discounts") {
      return Tab.display_text;
    } else {
      if (Tab.name === "Corporate IT" || Tab.name === "AppliedKids") {
        return Tab.name;
      } else {
        return ToTitleCase(Tab.name);
      }
    }
  };

  const Tabs = () => {
    return currentTab.map((Tab, Index) => {
      const TitleCaseName = getTitleName(Tab);
      const handleFav = (isFav) => {
        const newState = [...currentTab];
        newState[Index].IsFav = isFav;
        setCurrentTab(newState);
      };

      if ((resources && Tab.IsActive) || !resources) {
        return (
          <li key={Index} styleName={`subNavTab ${getNavLinkClass(Tab.url)}`}>
            <NavLink
              className="tab-name"
              onClick={() => {
                handleTabClick(Tab.url);
                if (
                  currentPath.split("/")[1] === "discounts" ||
                  currentPath.split("/")[1] === "resources"
                ) {
                  resetToTop();
                }
              }}
              data-cy={`subnav-link`}
              to={Tab.URL ? Tab.URL : Tab.url}
            >
              {TitleCaseName}
            </NavLink>
            {Tab.name !== "ALL" && Tab.IsFav !== undefined && (
              <div styleName="star-display" className="flex items-center">
                <Star
                  index={Index}
                  TeamId={TeamId}
                  page={page}
                  IsFav={Tab.IsFav}
                  TabName={Tab.announcement_type || Tab.SectionID}
                  handleFav={handleFav}
                />
              </div>
            )}
          </li>
        );
      }
    });
  };

  if (resources && !isMobile) {
    return (
      <div styleName="SubNavContainer" style={{ width, overflow: "hidden" }}>
        <ul styleName="subNavUl">
          <Tabs />
        </ul>
      </div>
    );
  }

  return (
    <div styleName="SubNavContainer" style={{ width: isMobileCitySelector ? "100%" : width, overflow: "hidden" }}>
      <Scrollbars
        styleName={resources ? "scrollbar" : ""}
        style={{ height: isMobile ? height * 1.25 : height, width }}
        autoHide
        universal
      >
        <ul styleName="subNavUl">
          <Tabs />
        </ul>
      </Scrollbars>
    </div>
  );
};

SubNav.propTypes = {
  height: propTypes.number,
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  page: propTypes.string,
  currentTab: propTypes.array.isRequired,
  setCurrentTab: propTypes.func,
  TeamId: propTypes.string,
  isMobile: propTypes.bool,
  history: propTypes.object,
  closeModal: propTypes.func,
  resources: propTypes.bool,
};

export default SubNav;
