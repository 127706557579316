import React, { useState } from "react";
import SubNav from "../SubNav/SubNav";
import Modal from "react-responsive-modal";
import { exitButton } from "_shared/modal";
// import { findIndex, propEq } from "ramda";
import propTypes from "prop-types";
// import { set } from "lodash";

const MobileCitySelector = ({
  // currentTab,
  isMobile,
  isMedScreen,
  //   setCurrentTab,
  title,
  width,
  history,
  height,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOnClick = () => {
    setOpenModal(!openModal);
  };

  const closeModal = () => {
    setOpenModal(false);
  };


  const currentTab = [
    { name: "Rancho Santa Margarita", map_type: "RSM", url: "/campusmaps/rsm" },
    { name: "Lake Forest", map_type: "LAKEFOREST", url: "/campusmaps/lakeforest" },
    { name: "Irvine", map_type: "IRVINE", url: "/campusmaps/irvine" },
    { name: "New York", map_type: "NEWYORK", url: "/campusmaps/newyork" },
    { name: "Georgia", map_type: "GEORGIA", url: "/campusmaps/georgia" },
    { name: "Amersfoort", map_type: "AMERSFOORT", url: "/campusmaps/amersfoort" },
    { name: "Nevada", map_type: "NEVADA", url: "/campusmaps/nevada" },
  ];

  const styles = {
    modal: {
      width: "100dvw",
      height: "100dvh",
      borderRadius: "0.5rem",
      // boxShadow: "0 0 20px rgba(241, 241, 242, 0.7)",
      padding: 0,
      //background: none,
      background: "#ffffff",
      overflow: "hidden",
    },
  };

  return (
    // <button
    //   className="mx-2 flex h-auto w-auto cursor-pointer flex-row rounded border-0 bg-teal-tints-70 py-2 px-4 font-bold text-white"
    //   onClick={handleOnClick}
    // >
    <div
      className={`flex w-auto cursor-pointer justify-between border border-solid border-[#fafbfb] bg-white md:w-[200px] lg:w-[300px] rounded-[4px] ${isMedScreen ? "h-fit" : "h-[34px]"}`}
      // className="mx-2 flex h-auto w-auto cursor-pointer flex-row rounded border-0 bg-teal-tints-70 py-2 px-4 font-bold text-white" //button
      // style={{
      //   width: width,
      //   height: "100dvh",
      // }}
      onClick={handleOnClick}
    >
        <div //take out for button
          // data-cy="mobile-collapsed_header"
          className={` ${isMedScreen ? "h-11" : ""} flex items-center ml-4 text-sm font-normal uppercase text-[#9da3a9]`}
          data-testid="mobile-city-selector-container"
        >
          {title}
        </div>
        <i //take out for button
          className="fal fa-chevron-down fa-lg ml-2 mr-4 flex cursor-pointer items-center text-[#e3e5e6]"
          onClick={handleOnClick}
        />
      <Modal
        // classNames=" md:w-[90dvw] rounded-lg shadow-[0 0 20px rgba(241, 241, 242, 0.7)] h-[100dvh] w-[100dvw] p-0 bg-none overflow-hidden"
        styles={styles}
        open={openModal}
        onClose={closeModal}
        closeIconSvgPath={exitButton}
      >
        <div
        style={{paddingTop: "30px"}}
        >
        <SubNav
          height={height}
          width={width}
          isMobile={true}
          isMobileCitySelector={true}
          closeModal={closeModal}
          history={history}
          //   page={page}
          currentTab={currentTab}
          //   setCurrentTab={setCurrentTab}
          //   TeamId={TeamId}
          //   resources={resources}
        />
        </div>
      </Modal>
    </div>

    // </button>
  );
};

MobileCitySelector.propTypes = {
  currentTab: propTypes.array,
  setCurrentTab: propTypes.func,
  title: propTypes.string,
  width: propTypes.number,
  history: propTypes.object,
  height: propTypes.number,
};

export default MobileCitySelector;
