import React, { useState, useEffect } from "react";
import logo from "_shared/images/delta.png";
import Navlinks from "../Navlinks/Navlinks";
import { Link } from "react-router-dom";
import "./mobileNav.scss";
import MobileCitySelector from "../CampusMaps/MobileCitySelector";
import BuildingModal from "../CampusMaps/BuildingModal/BuildingModal";
import { get } from "_shared/request";


const MobileNavBar = ({
  width,
  height,
  collapsed,
  setCollapsed,
  withBackground,
  user,
  isMobile,
  city,
  history,
}) => {
  const [map, setMap] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        setMap({});
        const { data } = await get({
          url: `/campusmap/am${city}`,
        });
        setMap(data);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, [city]);


  return (
    <div
      styleName={withBackground && collapsed ? "dark_container" : "container"}
    >
      <div
        styleName={
          withBackground && collapsed ? "dark_mode" : "inner_container"
        }
      />
      {!collapsed && (
        <Link to="/" onClick={() => setCollapsed(true)}>
          <img
            data-cy="applied-logo"
            style={{ top: 15, left: 20, position: "absolute", zIndex: 2 }}
            className="logo"
            styleName="fadeInText imgLogo"
            alt="screen background"
            src={logo.src}
            srcSet={logo.srcSet}
          />
        </Link>
      )}
      {!collapsed && (
        <i
          data-cy="mobile-nav-burger"
          styleName={"iconContainer fadeInText"}
          className="fal fa-times fa-2x"
          style={{ top: 16, right: 20, cursor: "pointer" }}
          role="button"
          onKeyPress={() => {
            setCollapsed(true);
          }}
          onClick={() => {
            setCollapsed(true);
          }}
          tabIndex={0}
        />
      )}
      {!collapsed && (
        <div styleName="sidenav_lg">
          <ul
            styleName="sidenav_lg__items fadeInText"
            style={{ display: collapsed ? "none" : "" }}
          >
            <Navlinks setCollapsed={setCollapsed} user={user} />
          </ul>
        </div>
      )}
      <div
        styleName="sidenav-opacity"
        style={{ width: "100vw", display: collapsed ? "none" : "", zIndex: 3 }}
      />
      {collapsed && (
        <i
          data-cy="mobile-nav-burger"
          styleName={
            withBackground
              ? "light_icon_container fadeInText"
              : "iconContainer fadeInText"
          }
          className="fal fa-bars fa-align-left fa-2x"
          style={{
            cursor: "pointer",
            top: 21,
            left: 11,
            color: withBackground ? "#c8cbcd" : "#fff",
          }}
          role="button"
          onKeyPress={() => {
            setCollapsed(false);
          }}
          onClick={() => {
            setCollapsed(false);
          }}
          tabIndex={0}
        />
      )}
      <div
        styleName="iconContainer"
        style={{
          top: 20,
          right: 11,
          jusitfyContent: "center",
          alignItems: "center",
          display: collapsed ? "flex" : "none",
        }}
      >
        <div style={{display: "flex"}}>
          <div style={{paddingRight: "24px"}}>
            <MobileCitySelector
            height={height}
            title={city}
            width={width}
            history={history}
            isMobile={isMobile}
            />
          </div>
            <BuildingModal
            buildingData={map}
            width={width}
            height={height}
            isMobile={isMobile}
            />
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;