import React from "react";
import "./MobileHeader.scss";

const MobileHeader = ({ title, withBackground, history, onTitleClick }) => {
  const generateURLtitle = () => {
    switch (title) {
      case "Applied Company Store":
        return `/applied-shop/home`;
      default:
        return ``;
    }
  };

  const noTitleList = ["Campus Maps"];
  const dontShow = noTitleList.includes(title);

  const returnElement = () => {
    return dontShow ? (
      <></>
    ) : (
      <p
        styleName={`mobileHeader ${withBackground ? "teal" : "white"}`}
        onClick={() => {
          onTitleClick ? history.push(generateURLtitle()) : () => {};
        }}
      >
        {title}
      </p>
    );
  };

  return returnElement();
};

export default MobileHeader;
