import React, { useEffect } from "react";
import { useNewsroom } from "_shared/NewsroomContext";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated, user } from "_shared/auth";
import { pathOr } from "ramda";

const ROUTEPATHENV = "applied-saved-route";

const setRoute = (route) => {
  global.localStorage.setItem(ROUTEPATHENV, route);
};

export const getRedirectRoute = () => {
  const path = global.localStorage.getItem(ROUTEPATHENV);
  return path;
};

const AuthorizedRoute = ({ render: Component, ...rest }) => {
  const {
    visitedNewsroom,
    setVisitedNewsroom,
    visitedNewsroomCount,
    setVisitedNewsroomCount,
  } = useNewsroom();

  // newsroom is currently not supported for browser widths less than 1024px
  const supportedBrowserWidth = window.innerWidth >= 1024;

  const currentPath = pathOr("/", ["location", "pathname"], rest);

  // show newsroom if user is US and has not visited newsroom
  const showNewsroom = currentPath === "/" && user?.isUS && !visitedNewsroom;

  // if current browser width is not supported or users that did not navigate to homepage first, set visitedNewsroom to true so that newsroom is not shown by default on resize and subsequent visit to homepage
  // For non-US based users and users that did not navigate to homepage first, increment visitedNewsroomCount. Count is used to toggle on the transition animation when opening newsroom.
  if (
    (currentPath !== "/" && currentPath !== "/Newsroom") ||
    (showNewsroom && !supportedBrowserWidth) ||
    user?.isUS === false
  ) {
    setVisitedNewsroom(true);
    if (visitedNewsroomCount === 0) {
      setVisitedNewsroomCount(visitedNewsroomCount + 1);
    }
  }

  const search = pathOr("", ["location", "search"], rest);
  useEffect(() => {
    setRoute(pathOr("/", ["location", "pathname"], rest) + search);
  }, [currentPath, isAuthenticated, search]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          showNewsroom && supportedBrowserWidth ? (
            <Redirect
              to={{
                pathname: "/Newsroom",
                state: { from: pathOr("/", ["location"], rest) },
              }}
            />
          ) : (
            <Component {...props} user={user} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: pathOr("/", ["location"], rest) },
            }}
          />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
