import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { includes } from "ramda";
import { formatInTimeZone, format } from "date-fns-tz";
import Clock from "react-clock";
import "./WorldClock.scss";

const formatString = "eee, MMM d h:mm:ss a zzz BBBB";

const genTime = (date, timeZone) => {
  const timeZoneTime = formatInTimeZone(date, timeZone, formatString).split(
    " "
  );
  return timeZoneTime;
};

const WorldClock = ({ collapsed, timeZone, analog = false }) => {
  const [clockString, setClock] = useState(genTime(new Date(), timeZone));

  useEffect(() => {
    const timer = setInterval(() => {
      setClock(genTime(new Date(), timeZone));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeZone]);

  const changeBG = () => {
    const hour = parseInt(clockString[3].split(":")[0], 10);
    const flex = clockString.slice(7, 10).join(" ");
    if (
      (includes("morning", flex) && hour > 7 && hour !== 12) ||
      includes("afternoon", flex)
    ) {
      return "";
    } else {
      return "black";
    }
  };

  const bgcolor = changeBG();

  return analog ? (
    <div style={{ position: "relative" }}>
      <div styleName="index--day">{`${clockString[0]} ${clockString[1]} ${clockString[2]}`}</div>
      <div styleName="index--time">
        {clockString[3].split(":").slice(0, 2).join(":")}
      </div>
      <div styleName="index--ampm">{clockString[4]}</div>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: -20,
        }}
      >
        <Clock value={clockString[3]} size={58} styleName={bgcolor} />
      </div>
    </div>
  ) : collapsed ? (
    <>
      <div styleName="collapsed-index--time">
        {clockString[3].split(":").slice(0, 2).join(":")}
      </div>
      <div styleName="index--ampm">{clockString[4]}</div>
    </>
  ) : (
    <>
      <div styleName="index--day">{`${clockString[0]} ${clockString[1]} ${clockString[2]}`}</div>
      <div styleName="index--time">
        {clockString[3].split(":").slice(0, 2).join(":")}
      </div>
      <div styleName="index--ampm">{clockString[4]}</div>
    </>
  );
};

WorldClock.propTypes = {
  timeZone: propTypes.string,
};

export default WorldClock;
