import React, {useState, useEffect} from "react";
import { pathOr, includes } from "ramda";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Navlinks.scss";
import { useFeature } from "_shared/feature.js";
import { useTranslation } from "react-i18next";

const Navlinks = ({ setCollapsed, user }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const campusMapsFeature = useFeature("campus maps");


  return (
    <>
      <li>
        <NavLink
          data-cy="navlink-root"
          style={{ textDecoration: "none" }}
          exact
          activeClassName={styles.activeLink}
          onClick={() => {
            setCollapsed(true);
            document.title = "myApplied - Home";
          }}
          to="/"
        >
          {t("Home")}
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-dashboard"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            setCollapsed(true);
            document.title = "myApplied - Dashboard";
          }}
          to="/dashboard/home"
        >
          {t("Dashboard")}
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-announcements"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Announcements";
            setCollapsed(true);
          }}
          to="/announcements"
        >
          {t("Announcements")}
        </NavLink>
      </li>
      {user?.isUS && (
        <li>
          <NavLink
            data-cy="navlink-apps"
            style={{ textDecoration: "none" }}
            activeClassName={styles.activeLink}
            onClick={() => {
              document.title = "myApplied - eCommerce";
              setCollapsed(true);
            }}
            isActive={(_, { pathname }) => {
              return includes("/applied-shop/", pathname);
            }}
            to="/applied-shop/home"
          >
            {t("Store")}
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          data-cy="navlink-campusmap"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Campus Maps";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/campusmap/", pathname)
          }}
          to="/campusmap/rsm"
        >
          {t("Campus Map")}
        </NavLink>
      </li>
      {campusMapsFeature && (
        <li>
          <NavLink
            data-cy="navlink-campusmap"
            style={{ textDecoration: "none" }}
            activeClassName={styles.activeLink}
            onClick={() => {
              document.title = "myApplied - Campus Maps";
              setCollapsed(true);
            }}
            isActive={(_, { pathname }) => {
              return includes("/campusmaps/", pathname)
            }}
            to="/campusmaps/rsm"
          >
            {t("Campus Map (New)")}
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          data-cy="navlink-directory"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Directory";
            setCollapsed(true);
          }}
          to="/directory"
        >
          {t("Directory")}
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-discounts"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Discounts";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/discounts/", pathname);
          }}
          to="/discounts/restaurant"
        >
          {t("Discounts")}
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-events"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Calendar";
            setCollapsed(true);
          }}
          to="/events"
        >
          {t("Events")}
        </NavLink>
      </li>
      {!pathOr(true, ["isSales"], user) && (
        <li>
          <NavLink
            data-cy="navlink-orgchart"
            style={{ textDecoration: "none" }}
            activeClassName={styles.activeLink}
            onClick={() => {
              document.title = "myApplied - Organizational Chart";
              setCollapsed(true);
            }}
            to={`/orgchart/${user.TeamId}`}
          >
            {t("Org Chart")}
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          data-cy="navlink-portals"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Portals";
            setCollapsed(true);
          }}
          to="/portals/"
        >
          {t("Portals")}
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-resources"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Resources";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/resources/", pathname);
          }}
          to="/resources/Payroll-&-Benefits"
        >
          {t("Resources")}
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="training-tree"
          style={{ textDecoration: "none" }}
          activeClassName={styles.activeLink}
          onClick={() => {
            document.title = "myApplied - Training Tree";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/training-tree/", pathname);
          }}
          to="/training-tree/1"
        >
          {t("Training Tree")}
        </NavLink>
      </li>
    </>
  );
};

export default Navlinks;
