import React, { useState, useEffect } from "react";
import logo from "_shared/images/delta.png";
import Navlinks from "../Navlinks/Navlinks";
import loadable from "@loadable/component";
import { Tooltip, Typography } from "@material-ui/core";
import SearchBox from "../SearchBox/SearchBox";
import { useFeature } from "_shared/feature.js";
import { pathOr } from "ramda";
import { Link } from "react-router-dom";
import "./mobileNav.scss";
import { useNotifications } from "../Notifications/Notifications.api";
import { getTotalPcfByRegion } from "../Notifications/Forms/FormAPI/Form.queries";

const getTooltipTypography = (title) => {
  return (
    <Typography
      style={{
        fontSize: 12,
        color: "#ffffff",
        fontFamily: "Open Sans",
      }}
    >
      {title}
    </Typography>
  );
};

const Notifications = loadable(() =>
  import(/* webpackPrefetch: true */ "../Notifications/Notifications")
);
const FeatureToggle = loadable(() =>
  import(/* webpackPrefetch: true */ "../FeatureToggle/FeatureToggle")
);

const formatTotal = (num) => {
  return num > 100 ? "99+" : num;
};

const MobileNavBar = ({
  collapsed,
  setCollapsed,
  handleSearch,
  handleSearchSubmit,
  withBackground,
  user,
  searchPlaceHolder,
  noSearch = false,
  isMobile,
  history,
}) => {
  const [openPendingActions, setOpenPendingActions] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [isFeatureToggleOpen, setIsFeatureToggleOpen] = useState(false);
  const notifications = useNotifications({ refetchOnMount: false });
  const featureToggle = useFeature("feature toggle");
  ///////////////////////////////////// PCF //////////////////////////////////////////////////
  const [isCsTeamMember, setIsCsTeamMember] = useState(false);
  const isAMR = useFeature("Amr-cs-team");
  const isAME = useFeature("Ame-cs-team");
  const isAML = useFeature("Aml-cs-team");
  const isAuNz = useFeature("Am(au/nz)-cs-team");
  const [totalPCF, setTotalPCF] = useState(0);

  useEffect(() => {
    if (isAMR || isAME || isAML || isAuNz) {
      let pcfTotal = getTotalPcfByRegion(isAMR, isAME, isAML, isAuNz).then(
        (result) => {
          console.log("result in SearchBar useEffect: ", result);
          setTotalPCF(result);
          return result;
        }
      );
      setIsCsTeamMember(true);
      console.log("pcf total in SearchBar: ", pcfTotal);
    }
  }, [isAMR, isAME, isAML, isAuNz]);

  ///////////////////////////////////// PCF //////////////////////////////////////////////////

  const handleNotificationsClick = () => {
    setOpenPendingActions(!openPendingActions);
    history.push("/notifications");
  };

  useEffect(() => {
    if (
      pathOr(false, ["location", "pathname", "indexOf"], history) &&
      history.location.pathname.indexOf("/notifications") !== -1
    ) {
      setOpenPendingActions(true);
    }
  }, [history]);

  return (
    <div
      styleName={withBackground && collapsed ? "light_container" : "container"}
    >
      <div
        styleName={
          withBackground && collapsed ? "light_mode" : "inner_container"
        }
      />
      {!collapsed && (
        <Link to="/" onClick={() => setCollapsed(true)}>
          <img
            data-cy="applied-logo"
            style={{ top: 15, left: 20, position: "absolute", zIndex: 2 }}
            className="logo"
            styleName="fadeInText imgLogo"
            alt="screen background"
            src={logo.src}
            srcSet={logo.srcSet}
          />
        </Link>
      )}
      {!collapsed && (
        <i
          data-cy="mobile-nav-burger"
          styleName={"iconContainer fadeInText"}
          className="fal fa-times fa-2x"
          style={{ top: 16, right: 20, cursor: "pointer" }}
          role="button"
          onKeyPress={() => {
            setCollapsed(true);
          }}
          onClick={() => {
            setCollapsed(true);
          }}
          tabIndex={0}
        />
      )}
      {!collapsed && (
        <div styleName="sidenav_lg">
          <ul
            styleName="sidenav_lg__items fadeInText"
            style={{ display: collapsed ? "none" : "" }}
          >
            <Navlinks setCollapsed={setCollapsed} user={user} />
          </ul>
        </div>
      )}
      <div
        styleName="sidenav-opacity"
        style={{ width: "100vw", display: collapsed ? "none" : "", zIndex: 3 }}
      />
      {collapsed && (
        <i
          data-cy="mobile-nav-burger"
          styleName={
            withBackground
              ? "light_icon_container fadeInText"
              : "iconContainer fadeInText"
          }
          className="fal fa-bars fa-align-left fa-2x"
          style={{
            cursor: "pointer",
            top: 21,
            left: 25,
            color: withBackground ? "#c8cbcd" : "#fff",
          }}
          role="button"
          onKeyPress={() => {
            setCollapsed(false);
          }}
          onClick={() => {
            setCollapsed(false);
          }}
          tabIndex={0}
        />
      )}
      {collapsed && !withBackground && (
        <i
          className="fal fa-2x fa-clipboard-list-check"
          onClick={handleNotificationsClick}
          style={{
            color: withBackground ? "#c8cbcd" : "#fff",
            cursor: "pointer",
            top: 21,
            right: 25,
            zIndex: 1,
          }}
          styleName="pending-actions-icon"
          data-cy="pending-actions"
        >
          {(notifications.data.totalPending > 0 || totalPCF > 0) &&
            (!notifications.isLoading || totalPCF > 0) && (
              <span className="badge">
                {formatTotal(totalPCF + notifications.data?.totalPending)}
              </span>
            )}
        </i>
      )}
      <div
        styleName="iconContainer"
        onClick={() => {
          setShowSub(!showSub);
        }}
        style={{
          top: 20,
          right: 25,
          jusitfyContent: "center",
          alignItems: "center",
          display: collapsed ? "flex" : "none",
        }}
      >
        {withBackground && !noSearch && (
          <div styleName="searchBoxPositioning">
            <SearchBox
              handleSearchSubmit={handleSearchSubmit}
              handleSearch={handleSearch}
              withBackground={withBackground}
              searchPlaceHolder={searchPlaceHolder}
              width={205}
            />
          </div>
        )}
        <Notifications
          openPendingActions={openPendingActions}
          setOpenPendingActions={setOpenPendingActions}
          isMobile={isMobile}
          history={history}
          user={user}
          TeamId={user.TeamId}
          totalPCF={totalPCF}
          isCsTeamMember={isCsTeamMember}
        />
        {featureToggle && !handleSearch && (
          <div style={{ height: "24px", marginRight: 53, marginTop: 2 }}>
            <Tooltip arrow title={getTooltipTypography("Feature Toggle")}>
              <i
                className="fas fa-book"
                onClick={() => setIsFeatureToggleOpen(true)}
              />
            </Tooltip>
            <FeatureToggle
              isFeatureToggleOpen={isFeatureToggleOpen}
              setIsFeatureToggleOpen={setIsFeatureToggleOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileNavBar;
