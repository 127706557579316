import React from "react";
import WorldClock from "../WorldClock/WorldClock";
import "./ClockOverlay.scss";
import { useTranslation } from "react-i18next";

const Overlay = ({ collapsed }) => {
  const { t } = useTranslation();
  return (
    <div
      styleName="overlayContainer"
      style={{
        bottom: 0,
        left: collapsed ? 70 : 250,
      }}
    >
      <div styleName="card">
        <p>{t("RSM")}</p>
        <WorldClock timeZone={"America/Los_Angeles"} analog />
      </div>
      <div styleName="card">
        <p>{t("Amsterdam")}</p>
        <WorldClock timeZone={"Europe/Amsterdam"} analog />
      </div>
      <div styleName="card">
        <p>{t("Beirut")}</p>
        <WorldClock timeZone={"Asia/Beirut"} analog />
      </div>
      <div styleName="card">
        <p>{t("Brisbane")}</p>
        <WorldClock timeZone={"Australia/Brisbane"} analog />
      </div>
      <div styleName="card">
        <p>{t("New Zealand")}</p>
        <WorldClock timeZone={"Pacific/Auckland"} analog />
      </div>
      <div styleName="card">
        <p>{t("Queretaro")}</p>
        <WorldClock timeZone={"America/Mexico_City"} analog />
      </div>
      <div styleName="card">
        <p>{t("Tokyo")}</p>
        <WorldClock timeZone={"Asia/Tokyo"} analog />
      </div>
    </div>
  );
};

export default Overlay;
