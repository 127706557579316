import React from "react";
import "./Loading.scss";

const LoadingComponent = ({
  width = 50,
  height = 50,
  size = 50,
  centered = false,
  style,
  progress,
}) => {
  return centered ? (
    <div
      styleName="spinner-container"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: progress ? "scale(2)" : null,
        ...style,
      }}
    >
      <svg
        width={width || size}
        height={height || size}
        viewBox="25 25 50 50"
        styleName="loading"
      >
        <circle
          styleName="circle"
          style={{ position: progress ? "absolute" : null }}
          cx="50"
          cy="50"
          r="20"
        />
      </svg>
    </div>
  ) : (
    <>
      <svg
        width={width || size}
        height={height || size}
        viewBox="25 25 50 50"
        styleName="loading"
        style={style}
      >
        <circle styleName="circle" cx="50" cy="50" r="20" />
      </svg>
    </>
  );
};
export default LoadingComponent;
