import React from "react";
import SearchBar from "../SearchBar/SearchBar";
import "./Header.scss";

const Header = ({
  title,
  searchPlaceHolder,
  history,
  handleSearch,
  noSearch,
  className,
  withBackground,
  handleSearchSubmit,
  user,
  dashboard = false,
  container,
  onTitleClick,
  isMobile,
  Ecommerce,
  newsroom,
  handleCloseNewsroom,
  newsroomWidth,
}) => {
  const generateURLtitle = () => {
    switch (title) {
      case "Applied Company Store":
        return `/applied-shop/home`;
      default:
        return ``;
    }
  };
  return (
    <div styleName="header-container">
      <h1
        styleName={`header-container__title ${
          (withBackground ? "teal" : "white",
          title === "Applied Newsroom" ? "no-shadow" : "shadow")
        }`}
        onClick={() => {
          onTitleClick ? history.push(generateURLtitle()) : () => {};
        }}
      >
        {title}
      </h1>
      <SearchBar
        className={className}
        noSearch={noSearch}
        withSearch={withBackground}
        withBackground={withBackground}
        handleSearch={handleSearch}
        handleSearchSubmit={handleSearchSubmit}
        searchPlaceHolder={searchPlaceHolder}
        history={history}
        user={user}
        dashboard={dashboard}
        container={container}
        isMobile={isMobile}
        Ecommerce={Ecommerce}
        newsroom={newsroom}
        handleCloseNewsroom={handleCloseNewsroom}
        newsroomWidth={newsroomWidth}
      />
    </div>
  );
};

export default Header;
