import React from "react";
import propTypes from "prop-types";
import logo from "_shared/images/delta.png";
import { Link } from "react-router-dom";
import globe from "_shared/images/global.svg";
import WorldClock from "../WorldClock/WorldClock";
import "./sidebar.scss";
import Navlinks from "../Navlinks/Navlinks";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Sidebar = ({
  collapsed,
  setCollapsed,
  showWorldClocks,
  setShowWorldClocks,
  user,
  isMobile,
}) => {
  return (
    <div
      id="sidenavbar"
      styleName="sidenav-sm"
      style={{ width: collapsed ? 70 : 250 }}
    >
      <div
        data-cy="sidebar"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        styleName="sidenav-opacity"
        style={{
          cursor: "pointer",
          width: collapsed ? 70 : 250,
          opacity: collapsed ? 0.3 : 0.6,
        }}
      />
      {!collapsed && (
        <Link styleName="navbar-brand" to="/">
          <img
            styleName="fadeInText"
            alt="screen background"
            onClick={() => {
              setCollapsed(true);
            }}
            src={logo.src}
            srcSet={logo.srcSet}
          />
        </Link>
      )}
      {!collapsed && (
        <div>
          <div styleName="expanded-clock-container fadeInText">
            <WorldClock collapsed={false} sidebar={true} timeZone={timeZone} />
            <img
              data-cy="globe"
              src={globe}
              styleName="globe-expanded"
              alt="globe"
              onClick={() => setShowWorldClocks(!showWorldClocks)}
            />
          </div>
        </div>
      )}
      <div
        styleName="icon-left--background"
        style={{ cursor: "pointer", display: collapsed ? "none" : "" }}
      >
        <i
          className="fas fa-chevron-left"
          onClick={() => {
            setCollapsed(true);
          }}
        ></i>
      </div>
      <div styleName="fadeInText" style={{ display: collapsed ? "" : "none" }}>
        <div styleName="expand-menu">
          <Link to="/" data-cy="sidebar-home-logo">
            <img
              styleName="delta-style"
              alt="delta"
              tabIndex={0}
              src={logo.src}
              srcSet={logo.srcSet}
            />
          </Link>
        </div>
        <div styleName="chevron-open">
          <i
            className="fas fa-chevron-right"
            onClick={() => {
              setCollapsed(false);
            }}
          ></i>
        </div>
        <img
          data-cy="globe"
          src={globe}
          styleName="globe"
          alt="globe"
          onClick={() => setShowWorldClocks(!showWorldClocks)}
        />
        <div styleName="index--time-date">
          <WorldClock collapsed={true} timeZone={timeZone} />
        </div>
      </div>
      <div styleName="sidenav_lg">
        <ul
          styleName="sidenav_lg__items fadeInText"
          style={{ display: collapsed ? "none" : "" }}
        >
          <Navlinks setCollapsed={setCollapsed} user={user} />
        </ul>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  collapsed: propTypes.bool.isRequired,
  setCollapsed: propTypes.func.isRequired,
};

export default Sidebar;
