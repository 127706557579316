import React from "react";
import InfoIcon from "_shared/images/icons/blue-i-tooltip-icon.svg";
import DismissIcon from "_shared/images/icons/back-x-icon.svg";
import clsx from "clsx";

export default function Alert({
  message,
  optionalMessage,
  setShowAlert,
  className,
}) {
  return (
    <div className={clsx("rounded-md bg-[#e5f5fc] p-4", className)}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <img className="flex h-4 w-4 items-center" src={InfoIcon} />
        </div>
        <div className="ml-3 whitespace-normal text-xs font-medium text-[#373D43]">
          <p>
            {message}
            <span className="hidden min-[1860px]:inline">
              {" "}
              {optionalMessage}
            </span>
          </p>
          <p className="min-[1860px]:hidden">{optionalMessage}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex rounded-md border-none bg-[#e5f5fc] p-1.5 text-[#373D43] focus:outline-none"
            >
              <span className="sr-only">Dismiss</span>
              <img
                className="h-3 w-3 cursor-pointer"
                src={DismissIcon}
                onClick={() => {
                  setShowAlert(false);
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
